import { FC } from "react";
import { useTranslation } from "react-i18next";
import { BillItem, PaymentStatus } from "../../types/bills";
import { Typography } from "@mui/material";
import CellBox from "../../components/common/table/CellBox";
import BorderRow from "../../components/common/table/BorderRow";
import DownloadIcon from "@mui/icons-material/Download";
import { theme } from "../../theme";
import { BillingPageRowStyles } from "./BillingPage.styles";
import { renderBillTypeSwitch, renderPaymentMethodSwitch, renderPaymentStatusSwitch } from "../../common/logic/BillingUtils";

const BillsTableRow: FC<{ billData: BillItem }> = ({ billData }) => {
  const { t } = useTranslation();

  const { invoiceNumber, invoiceDate, billType, paymentMethod, paymentStatus, analysisFileDownloadUrl, invoiceFileDownloadUrl } =
    billData;

  const handleOnDownload = (url: string) => {
    url ? window.open(url, '_blank') : console.error('No URL provided');
  };

  return (
    <BillingPageRowStyles>
      <CellBox firstCol={true}>
        <Typography>{invoiceNumber}</Typography>
      </CellBox>
      <CellBox>
        <Typography>
          {
            new Date(invoiceDate)
              .toLocaleDateString(undefined, {
                weekday: "long",
                day: "numeric",
                month: "numeric",
                year: "numeric",
              })
              .split("/")
              .join(".")
          }
        </Typography>
      </CellBox>
      <CellBox>
        <Typography>{renderBillTypeSwitch(billType)}</Typography>
      </CellBox>
      <CellBox>
        <Typography>{renderPaymentMethodSwitch(paymentMethod)}</Typography>
      </CellBox>
      <CellBox>
        <Typography
          sx={{
            color:
              paymentStatus === PaymentStatus.WaitingForPayment
                ? (theme) => theme.palette.companyMain.main
                : "",
          }}
        >
          {renderPaymentStatusSwitch(paymentStatus)}
        </Typography>
      </CellBox>
      {
        analysisFileDownloadUrl ?
          <CellBox sx={{ justifyContent: "center!important" }}>
            <button onClick={() => handleOnDownload(analysisFileDownloadUrl)}>
              <DownloadIcon
                sx={{
                  color: analysisFileDownloadUrl ? "#000" : theme.palette.border.subform,
                }}
              />
            </button>
          </CellBox> :
          <CellBox sx={{ justifyContent: "center!important" }}>
            <Typography>{t("billingPage.fileNotAvailable")}</Typography>
          </CellBox>
      }
      {
        invoiceFileDownloadUrl ?
          <CellBox sx={{ justifyContent: "center!important" }}>
            <button onClick={() => handleOnDownload(invoiceFileDownloadUrl)}>
              <DownloadIcon
                sx={{
                  color: invoiceFileDownloadUrl ? "#000" : theme.palette.border.subform,
                }}
              />
            </button>
          </CellBox> :
          <CellBox sx={{ justifyContent: "center!important" }}>
            <Typography>{t("billingPage.fileNotAvailable")}</Typography>
          </CellBox>
      }
      <BorderRow />
    </BillingPageRowStyles>
  );
};

export default BillsTableRow;
