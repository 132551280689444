import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FirstOpenCheckState {
    firstOpenCheck: boolean,
}

const initialState: FirstOpenCheckState = {
    firstOpenCheck: false,
};

const firstOpenCheckReducer = createSlice({
    name: "firstOpenCheck",
    initialState,
    reducers: {
        setfirstOpenCheck: (state, action: PayloadAction<boolean>) => {
            state.firstOpenCheck = action.payload
        },
    },
});

export const { setfirstOpenCheck } = firstOpenCheckReducer.actions;

export default firstOpenCheckReducer.reducer;
