import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { PaginationParameters } from "../../../api/Pagination";
import { BasicTableRowStyles, TableHeaderWrapper } from "../../../components/common/Common.styles";
import { useTranslation } from "react-i18next";
import { TableSortDirection } from "../../../common/logic/TableSortUtils";
import BorderRow from "../../../components/common/table/BorderRow";
import CellBox from "../../../components/common/table/CellBox";
import StatusDisplay from "../../../components/common/table/StatusDisplay";
import { ServerSidePagination } from "../../../components/common/table/ServerSidePagination";
import EmployeeIncentivationType from "../../../types/employeeIncentivationType";
import ServerSideTableColumnHeader, { SortDelegate } from "../../../components/common/table/ServerSideTableColumnHeader";
import { useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandedTable from "./ExpandedTable";
import { Link } from "react-router-dom";
import { TableLink } from "../../../components/common/TableLink";

interface Props {
    pagination: PaginationParameters;
    totalCount: number;
    onUpdatePagination: (newValue: PaginationParameters) => void;
    employees: EmployeeIncentivationType[];
}

export default function IncentivationOverviewTable(props: Props) {
    function onTableSortUpdate(direction: TableSortDirection | null, field: string | null) {
        props.onUpdatePagination({
            ...props.pagination,
            order: direction ?? undefined,
            sort: field ?? undefined,
        });
    }

    return <Box>
        <IncentivationOverviewTableHeader
            onTableSort={onTableSortUpdate}
            sortDirection={props.pagination.order}
            sortFieldName={props.pagination.sort} />
        <Box sx={{ display: "contents" }}>
            {
                props.employees.map((u) =>
                    <IncentivationOverviewTableRow
                        key={u.id}
                        row={u}
                    />)
            }
        </Box>
        <ServerSidePagination
            totalCount={props.totalCount}
            pagination={props.pagination}
            onUpdatePagination={props.onUpdatePagination} />
    </Box >
}

interface IncentivationOverviewTableHeaderProps {
    sortDirection: TableSortDirection | undefined;
    sortFieldName: string | undefined;
    onTableSort: SortDelegate;
    extraColumn?: string
}

function IncentivationOverviewTableHeader(props: IncentivationOverviewTableHeaderProps) {
    const { t } = useTranslation();
    const gridTemplate = `3fr 2fr 2fr 2fr 1fr`
    return <Box gridTemplateColumns={gridTemplate} display="grid">
        <TableHeaderWrapper>
            <ServerSideTableColumnHeader
                fieldName="Name"
                isSorted={true}
                label={t("tableColumnHeaders.name")}
                onTableSort={props.onTableSort}
                sortDirection={props.sortDirection}
                sortFieldName={props.sortFieldName}
                firstCol={true}
            />
            <ServerSideTableColumnHeader
                fieldName="ActionModuleName"
                isSorted={false}
                label={t("tableColumnHeaders.actionModule")}
                firstCol={false}
            />
            <ServerSideTableColumnHeader
                fieldName="Position"
                isSorted={true}
                label={t("tableColumnHeaders.position")}
                onTableSort={props.onTableSort}
                sortDirection={props.sortDirection}
                sortFieldName={props.sortFieldName}
            />
            <ServerSideTableColumnHeader
                fieldName="EmployeeNumber"
                isSorted={true}
                label={t("tableColumnHeaders.employeeNo")}
                onTableSort={props.onTableSort}
                sortDirection={props.sortDirection}
                sortFieldName={props.sortFieldName}
            />
            <ServerSideTableColumnHeader
                fieldName="status"
                label={t("tableColumnHeaders.status")}
                lastCol={true}
            />
            <BorderRow />
        </TableHeaderWrapper>
    </Box>
}

interface IncentivationOverviewTableRowProps {
    row: EmployeeIncentivationType,
}

function IncentivationOverviewTableRow(props: IncentivationOverviewTableRowProps) {
    const [open, setOpen] = useState(false);
    const gridTemplate = `3fr 2fr 2fr 2fr 1fr`
    return <>
        <Box gridTemplateColumns={gridTemplate} display="grid">
            <BasicTableRowStyles>
                <CellBox firstCol={true}>
                    <Typography>{props.row.name}</Typography>
                </CellBox>
                <TableLink to={`${props.row.actionModuleId}`}>
                    <CellBox contentPosition="left">
                        <Typography align="left">{props.row.actionModuleName}</Typography>
                    </CellBox>
                </TableLink>
                <CellBox>
                    <Typography>{props.row.position}</Typography>
                </CellBox>
                <CellBox>
                    <Typography>{props.row.employeeNumber}</Typography>
                </CellBox>
                <CellBox lastCol={true}>
                    <StatusDisplay status={props.row.status} />
                </CellBox>
            </BasicTableRowStyles>
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <ExpandedTable rowData={props.row} />
        </Collapse>
    </>
}