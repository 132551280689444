import { configureStore } from "@reduxjs/toolkit";
import groupsReducer from "./reducers/groupsReducer";
import actionsReducer from "./reducers/actionsReducer";
import taxFreeBenefitReducer from "./reducers/taxFreeBenefitReducer";
import accountManagementReducer from "./reducers/accountManagementReducer";
import actionModuleManagementReducer from "./reducers/actionModuleManagementReducer";
import billsReducer from "./reducers/billsReducer";
import reportingReducer from "./reducers/reportingReducer";
import certificatesReducer from "./reducers/certificatesReducer";
import tableManagementReducer from "./reducers/tableManagementReducer";
import notificationReducer from "./reducers/notificationReducer";
import configurationReducer from "./reducers/configurationReducer";
import featureFlagsReducer from "./reducers/featureFlagsReducer";
import firstOpenCheckReducer from "./reducers/firstOpenCheckReducer";

export const store = configureStore({
  reducer: {
    bills: billsReducer,
    groups: groupsReducer,
    actions: actionsReducer,
    taxFreeBenefit: taxFreeBenefitReducer,
    accountManagement: accountManagementReducer,
    actionManagement: actionModuleManagementReducer,
    reporting: reportingReducer,
    certificates: certificatesReducer,
    tableManagement: tableManagementReducer,
    notifications: notificationReducer,
    configuration: configurationReducer,
    firstOpenCheck: firstOpenCheckReducer,
    featureFlags: featureFlagsReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
