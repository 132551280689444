import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';

const SectionNotAvailable = () => {
    const { t } = useTranslation();

    return (
        <Typography>
            {t("featureFlags.sectionNotAvailable")}
        </Typography>
    )
}

export default SectionNotAvailable