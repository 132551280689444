import { FC } from "react";
import { useTranslation } from "react-i18next";
import { BillItem, PaymentStatus } from "../../../types/bills";
import { Box, Typography } from "@mui/material";
import CellBox from "../../../components/common/table/CellBox";
import BorderRow from "../../../components/common/table/BorderRow";
import { theme } from "../../../App";
import { useBillsEditMode } from "../../../store/selectors/billsSelectors";
import AddOrEditBillForm from "./edit/AddOrEditBillForm";
import { renderBillTypeSwitch, renderPaymentMethodSwitch, renderPaymentStatusSwitch } from "../../../common/logic/BillingUtils";
import DownloadBtn from "../../../components/common/table/DownloadBtn";

const BillsTableRow: FC<{ billData: BillItem }> = ({ billData }) => {
  const { companyName, invoiceNumber, invoiceDate, billType, paymentMethod, paymentStatus, analysisFileDownloadUrl, invoiceFileDownloadUrl } =
    billData;
  const isEditMode = useBillsEditMode();
  const { t } = useTranslation();

  const handleOnDownload = (url: string) => {
    url ? window.open(url, '_blank') : console.error('No URL provided');
  };

  if (isEditMode) {
    return <AddOrEditBillForm billItem={billData} />;
  }

  return (
    <Box
      sx={(theme) => ({
        display: "contents",
        "& > div": {
          height: "114px",
          justifyContent: "flex-start",
          alignItems: "center",
          button: {
            border: "none",
            outline: "none",
            padding: "none",
            background: "none",
            cursor: "pointer",
            svg: {
              width: "30px",
              height: "30px",
            },
          },
        },
      })}
    >
      <CellBox
        sx={{
          paddingLeft: "35px",
          [theme.breakpoints.down(1700)]: {
            "&": {
              padding: "0 0 0 25px!important",
            },
          },
        }}
      >
        <Typography>{companyName}</Typography>
      </CellBox>
      <CellBox>
        <Typography>{invoiceNumber}</Typography>
      </CellBox>
      <CellBox>
        <Typography>
          {
            new Date(invoiceDate)
              .toLocaleDateString(undefined, {
                weekday: "long",
                day: "numeric",
                month: "numeric",
                year: "numeric",
              })
              .split("/")
              .join(".")
          }
        </Typography>
      </CellBox>
      <CellBox>
        <Typography>{renderBillTypeSwitch(billType)}</Typography>
      </CellBox>
      <CellBox>
        <Typography>{renderPaymentMethodSwitch(paymentMethod)}</Typography>
      </CellBox>
      <CellBox>
        <Typography
          sx={{
            color:
              paymentStatus === PaymentStatus.Paid
                ? (theme) => theme.palette.companyMain.main
                : "",
          }}
        >
          {renderPaymentStatusSwitch(paymentStatus)}
        </Typography>
      </CellBox>
      {
        analysisFileDownloadUrl ?
          <CellBox sx={{ justifyContent: "center!important" }}>
            <DownloadBtn
              isDisabled={analysisFileDownloadUrl === null || analysisFileDownloadUrl === undefined}
              handleOnDownload={() => handleOnDownload(analysisFileDownloadUrl)}
            />
          </CellBox> :
          <CellBox sx={{ justifyContent: "center!important" }}>
            <Typography>{t("billingPage.fileNotAvailable")}</Typography>
          </CellBox>
      }
      {
        invoiceFileDownloadUrl ?
          <CellBox sx={{ justifyContent: "center!important" }}>
            <DownloadBtn
              isDisabled={invoiceFileDownloadUrl === null || invoiceFileDownloadUrl === undefined}
              handleOnDownload={() => handleOnDownload(invoiceFileDownloadUrl)}
            />
          </CellBox> :
          <CellBox sx={{ justifyContent: "center!important" }}>
            <Typography>{t("billingPage.fileNotAvailable")}</Typography>
          </CellBox>
      }
      <BorderRow />
    </Box>
  );
};

export default BillsTableRow;
