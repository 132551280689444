import { useTranslation } from "react-i18next";
import PageTitle from "../../../components/common/PageTitle/PageTitle";
import AppDesignContainer from "./AppDesignContainer";
import { useFeatureFlags } from "../../../store/selectors/featureFlagsSelectors";
import SectionNotAvailable from "../../../scaffold/ui/SectionNotAvailable";


const breadcrumbs = [
  {
    title: "configurationPage.appDesign",
    link: "configuration/app-design",
  },
];

const AppDesignPage = () => {
  const { t } = useTranslation()
  const featureFlags = useFeatureFlags()

  return (
    <>
      <PageTitle
        breadcrumbs={breadcrumbs}
        title={t("configurationPage.title")}
      />
      {
        !featureFlags.includes("AppCustomizationsPage") || featureFlags.includes("TestCompany")
          ?
          <SectionNotAvailable />
          :
          <AppDesignContainer />
      }
    </>
  );
};

export default AppDesignPage;