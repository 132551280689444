import { Outlet } from "react-router-dom";
import { Box, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { TopNav } from "./TopNav";
import { SideNav } from "./SideNav";
import { LayoutBox, LayoutWithNavBarStyles } from "./Layout.styles";
import { keyValueStorage } from "../../common/logic/KeyValueStore";
import { ModalComponent } from "../../components/common/ModalComponent";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../hooks/StoreHooks";
import { setfirstOpenCheck } from "../../store/reducers/firstOpenCheckReducer";
import { useFirstOpenCheck } from "../../store/selectors/firstOpenCheckSelector";

const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const LayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export function LayoutsWithNavbar() {
  const [navOpen, setNavOpen] = useState(false);
  const [firstOpenModal, setFirstOpenModal] = useState<boolean>(false)
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const featureFlags = keyValueStorage.getObject('featureFlags', "")
  let isFirstOpenTodayStoreValue = useFirstOpenCheck()

  useEffect(() => {
    if (featureFlags.includes("TestCompany") && isFirstOpenTodayStoreValue) {
      setFirstOpenModal(true)
    }
  }, [isFirstOpenTodayStoreValue]);

  const closeModal = () => {
    setFirstOpenModal(false)
    dispatch(setfirstOpenCheck(false))
  }

  const lastOpenDate = keyValueStorage.getObject('lastOpenDate', "")
  const currentDate = new Date()
  let lastOpenDateFormatted = new Date(lastOpenDate.replace(/^"|"$/g, ''));
  if (!lastOpenDate || (lastOpenDateFormatted.getFullYear() !== currentDate.getFullYear() ||
    lastOpenDateFormatted.getMonth() !== currentDate.getMonth() ||
    lastOpenDateFormatted.getDate() !== currentDate.getDate())) {
    dispatch(setfirstOpenCheck(true))
  }
  keyValueStorage.setObject("lastOpenDate", currentDate.toISOString());

  return (
    <LayoutWithNavBarStyles>
      <TopNav onNavOpen={() => setNavOpen(true)} />
      <SideNav onClose={() => setNavOpen(false)} open={navOpen} />
      <LayoutRoot>
        <LayoutContainer>
          <LayoutBox>
            <Outlet />
          </LayoutBox>
        </LayoutContainer>
      </LayoutRoot>

      <ModalComponent isOpen={firstOpenModal} onClose={closeModal}>
        <Box>{t("dialogModals.firstOpenToday")}</Box>
      </ModalComponent>
    </LayoutWithNavBarStyles>
  );
}
