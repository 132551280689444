import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FeatureFlagsInitialState {
    featureFlagsList: string[],
}

const initialState: FeatureFlagsInitialState = {
    featureFlagsList: ["initialValue"],
};

const featureFlagsReducer = createSlice({
    name: "featureFlags",
    initialState,
    reducers: {
        setFeatureFlagList: (state, action: PayloadAction<string[]>) => {
            state.featureFlagsList = action.payload
        },
    },
});

export const { setFeatureFlagList } = featureFlagsReducer.actions;

export default featureFlagsReducer.reducer;
